<template>
  <div class="commission">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>我的佣金</div>
    </div>
    <div class="top">
      <div class="top_total">可提现累计金币（个）</div>
      <div class="top_price">{{money.distribut_money_kt}}金币</div>
      <div class="top_click" @click="handleTxTime">申请提取</div>
      <div class="top_prompt">每月25号可提现上月有效金币</div>
    </div>
    <div class="bac">
      <div>
        <div>累计金币（个）</div>
        <div class="bac_bottom">{{money.distribut_money}}</div>
      </div>
      <div>
        <div>下月预计可提现金币（个）</div>
        <div class="bac_bottom">{{money.distribut_money_xia}}</div>
      </div>
    </div>
    <div class="tab">
      <van-tabs
        v-model="active"
        title-inactive-color="#333333"
        background="#f5f5f5"
        line-width="49"
        line-height="3"
        :border="false"
        color="#00B100"
      >
        <van-tab title="收入明细">
          <div class="tab_left">
            <div class="tab_one">
              <div class="tab_one_title" ref="selectDate">全部</div>
              <img src="../../assets/image/bottom.svg" alt @click="show = true" />
            </div>
            <div class="tab_two">
              <div class="two_item" v-for="item in List" :key="item.id">
                <div class="item_left">
                  <div>{{item.content}}</div>
                  <div class="item_left_date">{{item.addtime}}</div>
                </div>
                <div class="item_right">+{{item.increase}}</div>
              </div>
            </div>
          </div>
          <div class="none" v-show="(List instanceof Array)&&List.length==0">暂无明细</div>
        </van-tab>
        <van-tab title="提现记录">
          <div class="tab_right">
            <div class="item" v-for="item in rightList" :key="item.id">
              <div class="item_left">
                <div class="item_left_top">{{item.reduce}}</div>
                <div class="item_left_date">{{item.addtime}}</div>
              </div>
              <div
                class="item_right"
                :class="[item.status==0?'applying':'']"
              >{{item.status==0?"申请中":item.status==1?"申请成功":"申请不通过"}}</div>
            </div>
          </div>
          <div class="none" v-show="(rightList instanceof Array)&&rightList.length==0">暂无记录</div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 日期弹框 -->
    <van-popup
      v-model="show"
      position="bottom"
      :style="{ height: '250px' }"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        title="标题"
        :columns="columns"
        @change="onChange"
        swipe-duration
        item-height="50"
        visible-item-count="5"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      List: [],
      rightList: [],
      columns: [],
      show: false,
      money: {}
    };
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    onChange(picker, value, index) {
      console.log(picker, value, index);
      this.$refs.selectDate.innerHTML = value;
      this.show = false;
      if (value == "全部") {
        this.handleGetIncomeList(0);
      } else {
        this.handleGetIncomeList(value);
      }
    },
    async handleGetMoney() {
      const data = await this.$api.getMoney();
      if (data.data.code === 1) {
        this.money = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleGetIncomeList(value) {
      const data = await this.$api.getIncomeList({
        addtime: value ? value : 0
      });
      if (data.data.code === 1) {
        this.List = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
        this.List = [];
      }
    },
    async handleGetTxList() {
      const data = await this.$api.getTxList();
      if (data.data.code === 1) {
        this.rightList = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleGetDateList() {
      const data = await this.$api.getDateList();
      if (data.data.code === 1) {
        let list = ["全部"];
        this.columns = list.concat(data.data.data);
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleTxTime() {
      const data = await this.$api.txTime();
      if (data.data.code === 1) {
        this.$router.push({
          path: "/money/index"
        });
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    }
  },
  mounted() {
    this.handleGetMoney();
    this.handleGetIncomeList();
    this.handleGetTxList();
    this.handleGetDateList();
  }
};
</script>
<style lang="scss" scoped>
.commission {
  width: 375px;
  height: auto;
  // min-height: 100vh;
  background: #f5f5f5;
  padding-top: 40px;
  box-sizing: border-box;
  position: relative;
  .top {
    width: 375px;
    height: 200px;
    background: url("../../assets/image/per_bac.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 20px;
    box-sizing: border-box;
    .top_total {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 15px;
      padding-left: 20px;
    }
    .top_price {
      font-size: 25px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 25px;
      text-align: center;
      margin-top: 22px;
    }
    .top_click {
      width: 80px;
      height: 29px;
      background: rgba(255, 255, 255, 1);
      border-radius: 15px;
      font-size: 13px;
      color: rgba(16, 183, 16, 1);
      line-height: 29px;
      margin: 0 auto;
      margin-top: 16px;
      text-align: center;
    }
    .top_prompt {
      font-size: 10px;
      color: rgba(250, 46, 46, 1);
      line-height: 10px;
      margin-top: 15px;
      text-align: center;
    }
  }
  .bac {
    width: 347px;
    height: 72px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    position: absolute;
    top: 204px;
    left: 14px;
    font-size: 12px;
    color: rgba(51, 51, 51, 1);
    line-height: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    div {
      .bac_bottom {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 12px;
        margin-top: 12px;
      }
    }
  }
  .tab {
    margin-top: 36px;
    .tab_left {
      .tab_one {
        width: 80px;
        height: 20px;
        background: rgba(0, 177, 0, 1);
        border-radius: 10px;
        margin: 0 auto;
        margin-top: 15px;
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: space-around;
        align-items: center;
        img {
          width: 10px;
          height: 10px;
        }
      }
      .tab_two {
        width: 375px;
        height: auto;
        background: rgba(255, 255, 255, 1);
        margin-top: 10px;
        padding-bottom: 40px;
        box-sizing: border-box;
        .two_item {
          width: 351px;
          height: 76px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #ebebeb;
          margin: 0 auto;
          font-size: 15px;
          color: rgba(51, 51, 51, 1);
          line-height: 15px;
          align-items: center;
          .item_left {
            .item_left_date {
              font-size: 11px;
              color: rgba(153, 153, 153, 1);
              line-height: 11px;
              margin-top: 15px;
            }
          }
          .item_right {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
            line-height: 20px;
          }
        }
      }
    }
    .none {
      margin: 0 auto;
      text-align: center;
      font-size: 16px;
      color: #999;
      padding-top: 100px;
      background: #fff;
    }
    .tab_right {
      width: 375px;
      height: auto;
      background: rgba(255, 255, 255, 1);
      margin-top: 10px;
      padding-bottom: 40px;
      box-sizing: border-box;
      .item {
        width: 351px;
        height: 76px;
        border-bottom: 1px solid #ebebeb;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item_left {
          text-align: center;
          .item_left_top {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
            line-height: 15px;
          }
          .item_left_date {
            font-size: 11px;
            color: rgba(153, 153, 153, 1);
            line-height: 11px;
            margin-top: 15px;
          }
        }
        .item_right {
          font-size: 15px;
          line-height: 15px;
        }
        .applying {
          color: rgba(250, 46, 46, 1);
        }
      }
    }
  }
}
</style>